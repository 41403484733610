import React, { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const DonorboxForm = ({src}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://donorbox.org/widget.js';
    script.setAttribute('paypalExpress', 'true');
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <div style={{ position: 'relative', width: "400px", textAlign: 'center' }}>
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
          }}
        >
          <ClipLoader loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      )}
      <iframe
        src={src}
        name="donorbox"
        allow="payment"
        allowPaymentRequest
        seamless="seamless"
        frameBorder="0"
        scrolling="no"
        height="900px"
        width="100%"
        style={{
          maxWidth: "100%",
          minWidth: '250px',
          maxHeight: 'none',
          borderRadius: '8px',
          marginTop: '10px',
        }}
        onLoad={handleIframeLoad}
      ></iframe>
    </div>
  );
};

export default DonorboxForm;
